var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-1"},[_c('b-row',[_c('b-col',{staticClass:"clearfix",attrs:{"cols":"12","xl":"12","lg":"12","md":"12"}},[_c('h4',{staticClass:"float-left font-weight-bolder mt-8px"},[_vm._v("진행중인 미팅 조회")]),_c('div',{staticClass:"justify-content-center spinner-custom float-right ml-1",class:{ 'd-none' : _vm.active }},_vm._l((_vm.variants),function(variant){return _c('b-spinner',{key:variant,attrs:{"variant":variant}})}),1),_c('b-form-select',{staticClass:"float-right col-md-2",attrs:{"size":"","options":_vm.areaOption,"placeholder":"권역"},model:{value:(_vm.selectArea),callback:function ($$v) {_vm.selectArea=$$v},expression:"selectArea"}})],1)],1)],1),_c('div',{staticClass:"pl-1 pr-1"},[_c('vue-good-table',{directives:[{name:"dragscroll",rawName:"v-dragscroll",value:({ target: '.vgt-responsive' }),expression:"{ target: '.vgt-responsive' }"}],staticClass:"table good-table-custom table-light",attrs:{"columns":_vm.callsListColumns,"rows":this.items,"rtl":_vm.direction,"search-options":{
          enabled: false,
          trigger: 'enter',
          placeholder: '검색어를 입력해주세요.',
        },"sort-options":{
        enabled: false,
      },"pagination-options":{
          enabled: true,
          perPage:_vm.pageLength
      },"theme":"my-theme"},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'index')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.originalIndex + 1)+" ")]):_vm._e(),(props.column.field === 'tbCmsMasterName')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.formattedRow.tbCmsMasterName)+" ")]):_vm._e(),(props.column.field === 'tbCmsMasterIp')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.formattedRow.tbCmsMasterIp)+" ")]):_vm._e(),(props.column.field === 'name')?_c('div',{staticClass:"text-nowrap"},[_c('a',[_vm._v(" "+_vm._s(props.formattedRow.name)+" ")])]):_vm._e(),(props.column.field === 'callGuid')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.formattedRow.callGuid)+" ")]):_vm._e(),(props.column.field === 'callId')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.formattedRow.callId)+" ")]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-end flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"m-2",attrs:{"value":1,"total-rows":_vm.total,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" 조회된 목록이 없습니다. ")])])],1),_c('calls-detail',{attrs:{"item":this.detailItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }