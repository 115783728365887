<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0 font-weight-bolder">
          진행중인 미팅 상세
        </h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Header End -->

      <!-- Body -->
      <b-form
        @submit.prevent
      >
          <b-table
          ref="refInvoiceListTable"
          :items="items"
          responsive
          :fields="metSettingColums"
          primary-key="id"
          show-empty
          empty-text="조회된 목록이 없습니다."
          class="custom position-relative table-setting text-left mb-0"
          >
          </b-table>
      </b-form>
      <div class="p-10px">
        <vue-good-table
          :columns="columns"
          :rows="codecList"
          v-dragscroll="{ target: '.vgt-responsive' }"
          :search-options="{
              enabled: false,
              trigger: 'enter',
              placeholder: '검색어를 입력해주세요.',
            }"
          :sort-options="{
            enabled: false,
          }"
          :pagination-options="{
              enabled: true,
              perPage:pageLength
          }"
          theme="my-theme"
          class="table table-light"
          >
          <div slot="emptystate" class="text-center">
            조회된 목록이 없습니다.
          </div>
          <template
              slot="table-row"
              slot-scope="props"
          >
              <!-- Column: Name -->
              <div v-if="props.column.field === 'index'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
              </div>
              <div v-if="props.column.field === 'name'" class="text-nowrap">
                {{ props.formattedRow.name }}
              </div>
              <div v-if="props.column.field === 'callnumber'" class="text-nowrap">
                  {{ props.formattedRow.callnumber }}
              </div>
          </template>        
          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
              <div class="d-flex justify-content-end flex-wrap">
              <div>
                  <b-pagination
                  :value="1"
                  v-model="currentPage"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="m-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                  <template #prev-text>
                      <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      />
                  </template>
                  <template #next-text>
                      <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                      />
                  </template>
                  </b-pagination>
              </div>
              </div>
          </template>
          </vue-good-table>
        </div>
    </template>
  </b-sidebar>
</template>
<style>
.custom.table-setting th div{
  display: none;
}
.custom.table-setting table thead th:first-child{
  width: 29%;
}
.custom.table-setting table thead th{
  padding: 0;
}
.custom.table-setting table tbody td{
  padding-left: 1.5rem !important;
  text-align: left;
}
</style>
<script>
import {
  BSidebar, BForm, BTable, VBToggle, BPagination
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { dragscroll } from 'vue-dragscroll'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BSidebar, BForm, BTable, VueGoodTable, BPagination
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    dragscroll,
  },
  props: {
    item: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {
      },  
      metSettingColums: [
        { key: 'ce_ver', label: '미팅룸 상세'},
        { key: 'commend', label: ''},
      ],
      items: [
        { 
          'ce_ver': '미팅룸 이름',
          'commend': '',
        },
        {
          'ce_ver': 'Call Type',
          'commend': '',
        },
        {
          'ce_ver': '회의 시간',
          'commend': 'HH:mm:ss',
        },
        {
          'ce_ver': '현재 참석자',
          'commend': '0',
        },
        {
          'ce_ver': '최대 참석자',
          'commend': '0',
        },
        {
          'ce_ver': '녹화 여부',
          'commend': 'false',
        },
        {
          'ce_ver': '스트리밍 여부',
          'commend': 'false',
        },
        {
          'ce_ver': '잠금 여부',
          'commend': 'false',
        },
      ],
      columns: [
        { field: 'index', label: '번호', width: '53px'},
        { field: 'name', label: '이름'},
        { field: 'callnumber', label: 'Call Number'},
      ],
      codecList: [],
      // pagination
      pageLength: 10,
      perPage: 10,
      currentPage: 1,
      total: 0,
    }
  },
  watch: {
    /**
     * Calls Detail Item Set
     * @created 2021-04-26
     * @author 박재헌
     * @param {Object} item 진행중인 미팅 상세내용
     * @comment CallsList.vue에서 상세내용 item Props 받은 후 세팅
     */
    // item = []
    item(val) {
      this.codecList = []
      if(val[0].callGuid){
        this.codecList = val
        this.total = this.codecList.length
      }
      this.items = [
        { 
          'ce_ver': '미팅룸 이름',
          'commend': val[0].name,
        },
        {
          'ce_ver': 'Call Type',
          'commend': val[0].callType,
        },
        {
          'ce_ver': 'Call Guid',
          'commend': val[0].callGuid,
        },
        {
          'ce_ver': '회의 시간',
          'commend': val[0].durationSeconds,
        },
        {
          'ce_ver': '현재 참석자',
          'commend': val[0].numCallLegs,
        },
        {
          'ce_ver': '최대 참석자',
          'commend': val[0].maxCallLegs,
        },
        {
          'ce_ver': '녹화 여부',
          'commend': val[0].recording,
        },
        {
          'ce_ver': '스트리밍 여부',
          'commend': val[0].streaming,
        },
        {
          'ce_ver': '잠금 여부',
          'commend': val[0].locked,
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>

</style>
