<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-1">
      <!-- Table Top -->
      <b-row>
        <!-- 타이틀 -->
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
          class="clearfix"
        >
          <h4 class="float-left font-weight-bolder mt-8px">진행중인 미팅 조회</h4>
          <div class="justify-content-center spinner-custom float-right ml-1" :class="{ 'd-none' : active }">
              <b-spinner
                v-for="variant in variants"
                :variant="variant"
                :key="variant"
              ></b-spinner>
          </div>
          <b-form-select
              class="float-right col-md-2"
              v-model="selectArea"
              size=""
              :options="areaOption"
              placeholder="권역"
            />
        </b-col>
        <!-- 타이틀 END -->
      </b-row>
    </div>
    <div class="pl-1 pr-1">
    <!-- table -->
        <vue-good-table
        :columns="callsListColumns"
        :rows="this.items"
        :rtl="direction"
        v-dragscroll="{ target: '.vgt-responsive' }"
        :search-options="{
            enabled: false,
            trigger: 'enter',
            placeholder: '검색어를 입력해주세요.',
          }"
        :sort-options="{
          enabled: false,
        }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        
        theme="my-theme"
        class="table good-table-custom table-light"
        @on-row-click="onRowClick"
        >
        <div slot="emptystate" class="text-center">
          조회된 목록이 없습니다.
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >
            <!-- Column: Name -->
            <div v-if="props.column.field === 'index'" class="text-nowrap">
                {{ props.row.originalIndex + 1 }}
            </div>
            <div v-if="props.column.field === 'tbCmsMasterName'" class="text-nowrap">
                {{ props.formattedRow.tbCmsMasterName }}
            </div>
            <div v-if="props.column.field === 'tbCmsMasterIp'" class="text-nowrap">
                {{ props.formattedRow.tbCmsMasterIp }}
            </div>
            <div v-if="props.column.field === 'name'" class="text-nowrap">
              <a>
                {{ props.formattedRow.name }}
              </a>
            </div>
            <div v-if="props.column.field === 'callGuid'" class="text-nowrap">
                {{ props.formattedRow.callGuid }}
            </div>
            <div v-if="props.column.field === 'callId'" class="text-nowrap">
                {{ props.formattedRow.callId }}
            </div>
        </template>
        
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-end flex-wrap">
            <div>
                <b-pagination
                :value="1"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="m-2"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
    </div>
    <calls-detail :item="this.detailItem"></calls-detail>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormSelect, BButton, VBToggle, BSpinner,
  BPagination,
} from 'bootstrap-vue'

import { dragscroll } from 'vue-dragscroll'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

import Ripple from 'vue-ripple-directive'
import CallsDetail from './CallsDetail.vue'

const qs = require('qs')

export default {
    directives: {
    Ripple,
    dragscroll,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormSelect,
    BButton,
    BSpinner,
    BPagination,
    VueGoodTable,
    CallsDetail,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: true,
    },
    total: {
      type: String,
      default: '0',
    },
    areaOption: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      data: {
      },
      pageLength: 10,
      dir: false,
      searchItem: '',
      callsDetailUrl: '/cmscallsdetail/selectCmsCallsDetailListJson',
      variants: ['primary'],
      callsListColumns: [
        { field: 'index', label: '번호'},
        { field: 'tbCmsMasterName', label: '권역명', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '권역명', // placeholder for filter input
          }, 
        },
        { field: 'tbCmsMasterIp', label: '권역 IP', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '권역 IP', // placeholder for filter input
          },
        },
        { field: 'name', label: '이름', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '이름', // placeholder for filter input
          },
        },
        { field: 'callGuid', label: 'Call Guid', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Call Guid', // placeholder for filter input
          },        
        },
      ],
      detailSet: [
        {
          sn: 0,
          serverSn: 0,
          serverName: '',
          serverIp: '',
          callsSn: '',
          callsName: '',
          name: '',
          callnumber: '',
          totCalls: '',
          limitCalls: '',
          callGuid: '',
          callType: '',
          durationSeconds: 'HH:mm:ss',
          numCallLegs: '',
          maxCallLegs: '',
          locked: 'false',
          recording: 'false',
          streaming: 'false',
        } // detailItem[0]
         // detailItem[1] 배열의 시작은 0부터 
      ],
      detailItem: [],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      selectArea: 0,
      param: {},
      sn: 0,
    }
  },
  watch: {
    currentPage(val){
      this.setOffset(val)
    },
    selectArea(val){
      // this.selectArea = val
      // this.sn = val
      this.$emit("getSn", val)
    }
  },
  mounted() {
    // console.log('List total', this.total)
    // this.setCmsServer()
    /**
     * CMS 위젯 이동 서버 자동선택
     * @created 2021-04-16
     * @author 박재헌
     * @param {number} $route.query.selectArea this
     * 서버순번으로 서버선택 셀렉트 val 자동 선택
     */
    if(this.$route.query.selectArea != undefined || this.$route.query.selectArea != null){
      this.selectArea = this.$route.query.selectArea
    }else{
      
    }
    this.$emit("getSn", this.selectArea)
  },
  computed: {
    rows() {
      return this.items.length
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    /**
     * CMS 행 클릭 상세조회 이벤트
     * @created 2021-04-20
     * @author 박재헌
     * @param {Array} data 테이블 내부 컴포넌트 행 데이터
     * @comment 상세조회할 미팅리스트의 데이터 안 callGuid로 상세조회 API 실행
     */
    onRowClick(data, d) {
      this.getCallsDetailProps(data.row.callGuid)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-send-invoice')
    },
    /**
     * CMS 페이징
     * @created 2021-04-20
     * @author 박재헌
     * @param {Number} currentPage 페이지 Index
     * @commnet currentPage를 부모 Calls 컴포넌트에 전송, 페이징하여 리스트 API 재실행
     */
    setOffset(){
      // if(this.currentPage === 1){
        this.$emit('callParent', this.currentPage)
      // }else{
        // this.$emit('callParent', (this.currentPage)
      // }
    },
    /**
     * CMS Calls 상세조회 API
     * @created 2021-04-20
     * @author 박재헌
     * @augments {String} item(ip)
     * @param {String} item
     * @param {Number} sn API 파라미터
     * @param {String} apiGb API 파라미터
     * @return {Array} response API 호출 성공 시 데이터
     * @comment 문자열 item을 post 주소로 입력, 파라미터를 전송하여 해당 진행중인 미팅의 상세조회 정보를 가져온다.
     */
    getCallsDetailProps(callGuid) {
      this.detailItem = this.detailSet
      this.param = {
        callGuid: callGuid,
      }
      this.$jBack({
          method: 'post',
          url: this.callsDetailUrl,
          data: qs.stringify(this.param)
        })
      // this.$cmsHttp.post(`${this.callsDetailUrl}${item}`)
      .then(response => {
        // console.log('Detail ************* ', response)
        if(response.data.resultList.length > 0){
          this.detailItem = response.data.resultList
        }else{
          this.detailItem = this.detailSet
        }
      })
      .catch(error => {
        console.log(error)
        alert('미팅 예약 상세조회에 실패하였습니다.')
      })
    },
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
